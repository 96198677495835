// Generated by Framer (3def70c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./GVEQozamc-0.js";

const cycleOrder = ["eBtfyw1uF", "kmNfWLYPQ", "NUZVW_bXy", "vGIRFZcPx", "tZsfw1Y_M", "jtG42mEBF"];

const serializationHash = "framer-iaUGv"

const variantClassNames = {eBtfyw1uF: "framer-v-pkon2c", jtG42mEBF: "framer-v-1n6du51", kmNfWLYPQ: "framer-v-6dda56", NUZVW_bXy: "framer-v-9b8bey", tZsfw1Y_M: "framer-v-1nenxv4", vGIRFZcPx: "framer-v-1pmhqug"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {ZULQJWnvF: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {L: "jtG42mEBF", Large: "NUZVW_bXy", M: "tZsfw1Y_M", Mid: "kmNfWLYPQ", S: "vGIRFZcPx", Small: "eBtfyw1uF"}

const getProps = ({height, id, image, mouseEnter, width, ...props}) => { return {...props, aD9M5OzGK: mouseEnter ?? props.aD9M5OzGK, nx7UATYL7: image ?? props.nx7UATYL7 ?? {src: "https://framerusercontent.com/images/NISqJ6CyMikO5fcmW64vvqBynP4.png"}, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "eBtfyw1uF"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string};mouseEnter?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, nx7UATYL7, aD9M5OzGK, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "eBtfyw1uF", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter1jhm7dn = activeVariantCallback(async (...args) => {
setGestureState({isHovered: true})
if (aD9M5OzGK) {const res = await aD9M5OzGK(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-pkon2c", className, classNames)} data-framer-name={"Small"} data-highlight layoutDependency={layoutDependency} layoutId={"eBtfyw1uF"} onMouseEnter={onMouseEnter1jhm7dn} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({jtG42mEBF: {"data-framer-name": "L"}, kmNfWLYPQ: {"data-framer-name": "Mid"}, NUZVW_bXy: {"data-framer-name": "Large"}, tZsfw1Y_M: {"data-framer-name": "M"}, vGIRFZcPx: {"data-framer-name": "S"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", intrinsicHeight: 128, intrinsicWidth: 128, pixelHeight: 256, pixelWidth: 256, sizes: "70px", ...toResponsiveImage(nx7UATYL7)}} className={"framer-1mlrwgu"} data-framer-name={"Safari"} layoutDependency={layoutDependency} layoutId={"HJRA8J0C0"} {...addPropertyOverrides({jtG42mEBF: {background: {alt: "", fit: "fill", intrinsicHeight: 128, intrinsicWidth: 128, pixelHeight: 256, pixelWidth: 256, sizes: "66px", ...toResponsiveImage(nx7UATYL7)}}, kmNfWLYPQ: {background: {alt: "", fit: "fill", intrinsicHeight: 128, intrinsicWidth: 128, pixelHeight: 256, pixelWidth: 256, sizes: "90px", ...toResponsiveImage(nx7UATYL7)}}, NUZVW_bXy: {background: {alt: "", fit: "fill", intrinsicHeight: 128, intrinsicWidth: 128, pixelHeight: 256, pixelWidth: 256, sizes: "110px", ...toResponsiveImage(nx7UATYL7)}}, tZsfw1Y_M: {background: {alt: "", fit: "fill", intrinsicHeight: 128, intrinsicWidth: 128, pixelHeight: 256, pixelWidth: 256, sizes: "54px", ...toResponsiveImage(nx7UATYL7)}}, vGIRFZcPx: {background: {alt: "", fit: "fill", intrinsicHeight: 128, intrinsicWidth: 128, pixelHeight: 256, pixelWidth: 256, sizes: "42px", ...toResponsiveImage(nx7UATYL7)}}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-iaUGv.framer-i7b9d0, .framer-iaUGv .framer-i7b9d0 { display: block; }", ".framer-iaUGv.framer-pkon2c { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: min-content; }", ".framer-iaUGv .framer-1mlrwgu { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 70px); overflow: visible; position: relative; width: 70px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-iaUGv.framer-pkon2c { gap: 0px; } .framer-iaUGv.framer-pkon2c > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-iaUGv.framer-pkon2c > :first-child { margin-left: 0px; } .framer-iaUGv.framer-pkon2c > :last-child { margin-right: 0px; } }", ".framer-iaUGv.framer-v-6dda56 .framer-1mlrwgu { height: var(--framer-aspect-ratio-supported, 90px); width: 90px; }", ".framer-iaUGv.framer-v-9b8bey .framer-1mlrwgu { height: var(--framer-aspect-ratio-supported, 110px); width: 110px; }", ".framer-iaUGv.framer-v-1pmhqug .framer-1mlrwgu { height: var(--framer-aspect-ratio-supported, 42px); width: 42px; }", ".framer-iaUGv.framer-v-1nenxv4 .framer-1mlrwgu { height: var(--framer-aspect-ratio-supported, 54px); width: 54px; }", ".framer-iaUGv.framer-v-1n6du51 .framer-1mlrwgu { height: var(--framer-aspect-ratio-supported, 66px); width: 66px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 70
 * @framerIntrinsicWidth 70
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"kmNfWLYPQ":{"layout":["auto","auto"]},"NUZVW_bXy":{"layout":["auto","auto"]},"vGIRFZcPx":{"layout":["auto","auto"]},"tZsfw1Y_M":{"layout":["auto","auto"]},"jtG42mEBF":{"layout":["auto","auto"]}}}
 * @framerVariables {"nx7UATYL7":"image","aD9M5OzGK":"mouseEnter"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerGVEQozamc: React.ComponentType<Props> = withCSS(Component, css, "framer-iaUGv") as typeof Component;
export default FramerGVEQozamc;

FramerGVEQozamc.displayName = "Safari";

FramerGVEQozamc.defaultProps = {height: 70, width: 70};

addPropertyControls(FramerGVEQozamc, {variant: {options: ["eBtfyw1uF", "kmNfWLYPQ", "NUZVW_bXy", "vGIRFZcPx", "tZsfw1Y_M", "jtG42mEBF"], optionTitles: ["Small", "Mid", "Large", "S", "M", "L"], title: "Variant", type: ControlType.Enum}, nx7UATYL7: {__defaultAssetReference: "data:framer/asset-reference,NISqJ6CyMikO5fcmW64vvqBynP4.png?originalFilename=image.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}, aD9M5OzGK: {title: "Mouse Enter", type: ControlType.EventHandler}} as any)

addFonts(FramerGVEQozamc, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})